h1 {
  font-size: 36px;
  font-weight: 500;
}

p {
  font-size: 18px;
  font-weight: 400;
}

.layout {
  font-family: Avenir, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 116px;
}

.site-layout-content {
  background: #fff;
  padding: 0px;
}

.section-title {
  color: #f7f7f7;
  font-size: 168px;
  font-weight: 900;
  line-height: 168px;
}

@media only screen and (max-width: 1224px) {
  .layout {
    padding-top: 92px;
  }
  h1 {
    font-size: 28px;
  }
  p {
    font-size: 16px;
  }
  .section-title {
    font-size: 120px;
    line-height: 120px;
  }
}

@media only screen and (max-width: 960px) {
  .layout {
    padding-top: 84px;
  }

  h1 {
    font-size: 24px;
  }

  .section-title {
    font-size: 92px;
    line-height: 92px;
  }
}

@media only screen and (max-width: 648px) {
  .layout {
    padding-top: 68px;
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  .section-title {
    font-size: 52px;
    line-height: 52px;
  }
}
