.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25%;
  max-width: 260px;
  border-right: 1px solid #0087cd;
  padding: 0 18px;
}

.logo-container a {
  text-align: center;
}

.social-media-logos-container {
  height: 100%;
  width: 200px;
  padding: 0 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid #0087cd;
}

.logo-container img {
  width: 90%;
  max-width: 310px;
}

.ant-layout-header {
  height: 116px !important;
  background: #ffffff !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.4s;
}

.ant-layout-header.sticky {
  height: 76px !important;
}

.ant-layout-header > ul,
.ant-drawer-body > ul {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.ant-drawer-body > ul {
  display: block;
  text-align: center;
}

.ant-layout-header > ul > li > a {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 500;
}

.ant-layout-header > ul > li.active > a {
  color: #0087cd;
}

.ant-drawer-body > ul > li > a {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  line-height: 44px;
}

.ant-layout-header > ul > li > a:hover,
.ant-drawer-body > ul > li > a:hover {
  color: #0087cd;
}

.hamburger-container {
  height: 100%;
  align-items: center;
  padding-left: 14px;
  display: none;
}

.ant-drawer-content-wrapper {
  top: 56px;
}

@media only screen and (max-width: 1224px) {
  .logo-container {
    width: 200px;
    padding: 14px 4px;
  }

  .social-media-logos-container {
    width: 120px;
    padding: 24px;
  }

  .logo-container img {
    max-width: 160px;
  }

  .ant-layout-header {
    height: 92px !important;
  }

  .ant-layout-header.sticky {
    height: 66px !important;
  }

  .ant-layout-header > ul > li > a {
    font-size: 16px;
  }

  .social-media-logos-container .insta-logo {
    height: 22px;
  }

  .social-media-logos-container .email-logo {
    height: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .logo-container {
    width: 120px;
    padding: 10px 4px;
  }

  .social-media-logos-container {
    width: 90px;
    padding: 12px;
    display: none;
  }

  .ant-layout-header {
    height: 84px !important;
  }

  .ant-layout-header.sticky {
    height: 56px !important;
  }

  .ant-layout-header > ul > li > a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 648px) {
  .ant-layout-header > ul {
    display: none;
  }

  .ant-layout-header {
    height: 68px !important;
    justify-content: left;
  }

  .logo-container {
    width: auto;
    border-right: none;
  }

  .logo-container img {
    max-width: 140px;
  }

  .hamburger-container {
    display: flex;
  }
}
